import React, { useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { UserStore } from '../../stores/user';
import { WordsStore } from '../../stores/words';
import { Word } from '../../sevices/types';
import { useLocation, useNavigate } from 'react-router-dom';

interface TrainingProps {
  userStore?: UserStore;
  wordsStore?: WordsStore;
}

const Training: React.FC<TrainingProps> =  inject('userStore', 'wordsStore')(observer((props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { wordsStore } = props
  const urlParams = new URLSearchParams(location.search);
  const direct = urlParams.get('direct') === '1';

  useEffect(() => { wordsStore?.getTrain() }, [])

  const getVariant = (variant: Word) => {
    const answer = wordsStore?.getCurrentAnswer()
    let color = 'bg-indigo-50';
    if (answer && answer.time > 0) {
      if (!answer.success && variant.id === wordsStore?.getLastAnswerId()) {
        color = 'bg-red-500'
      }
      if (variant.id === answer.id) {
        color = 'bg-green-500'
      }
    }
    return (
      <div key={variant.id} className='w-full pb-4'>
        <button
          className={`w-full rounded-md ${color} px-3.5 py-3.5 text-lg font-semibold text-gray-900 shadow-sm`}
          onClick={() => setVariant(variant)}
          disabled={(answer?.time || 0) > 0}
        >
          { direct ? variant.translate : variant.word}
        </button>
      </div>
    )
  }

  const setVariant = async (variant: Word) => {
    if (!wordsStore) return;
    const ended = await wordsStore.setAnswer(variant.id)
    if (ended) {
      navigate('/')
    }
  }

  const currentTrain = wordsStore?.getTrainItem()
  if (!currentTrain) return null;
  
  return (
    <div className='absolute overflow-hidden w-full h-full'>
      <div className="flex flex-1 flex-col relative h-full overflow-y-auto">
        <div className="flex flex-1 flex-col px-4 py-4  bg-black">
          <div className="flex flex-1 flex-row align-middle items-center bg-neutral-900 rounded-xl mb-4">
            <div className='w-full text-3xl text-center'>
              {direct && currentTrain.word.meta.artikel ? currentTrain.word.meta.artikel+ ' ' : null}
              {direct ? currentTrain.word.word : currentTrain.word.translate}
            </div>
          </div>

          <div className="flex flex-col flex-row align-middle items-center">
            { currentTrain.variants.map(it => getVariant(it)) }
          </div>
        </div>
      </div>
    </div>
  );
}));



export default Training;
