import { createBrowserRouter, Outlet } from "react-router-dom";
import TabBar from "./components/tabbar";
import Home from "./screens/home";
import Training from "./screens/training";
import Referals from "./screens/referals";
import Tasks from "./screens/tasks";
import { showTabbar } from "./settings";


function LayoutWithTabBar() {
  return (
    <>
      <Outlet />
      { showTabbar && <TabBar /> }
    </>
  );
}

const routes = [
  {
    path: '/',
    element: <LayoutWithTabBar />,
    children: [
      { path: '/', element: <Home /> },
      { path: '/tasks', element: <Tasks /> },
      { path: '/referals', element: <Referals /> },
      { path: '/training', element: <Training/> }
    ],
  },
];
export const router = createBrowserRouter(routes);