import { SettingsStore } from "../stores/settings";
import { Answer, ITransport, Result, Stats, TrainItem } from "./types";

export interface ISettingsService {
  getLang(): string
  setLang(language: string): void
}

export class SettingsService implements ISettingsService {  
  constructor() {}

  getLang(): string {
    const _data = localStorage.getItem('settings')
    if (!_data) return 'de'
    const settings = JSON.parse(_data)
    return settings && settings.lang ? settings.lang : 'de'
  }

  setLang(language: string): void {
    const _data = localStorage.getItem('settings') || {}
    const settings = {
      lang: 'de',
      ..._data
    }
    settings.lang = language
    localStorage.setItem('settings', JSON.stringify(settings))
  }
}