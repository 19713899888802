import { makeAutoObservable } from 'mobx';
import { getWordsService } from '../sevices/singletones';
import { IWordsService } from '../sevices/wordsService';
import { Answer, Stats, TrainItem } from '../sevices/types';
import { SettingsStore } from './settings';


export class WordsStore {
  loaded: boolean = false
  stats: Stats|null = null
  train: TrainItem[] = []
  currentIndex: number = 0
  time: number = 0
  answers: Answer[] = []
  lastAnswers: number[] = []

  private wordsService: IWordsService

  private settingsStore: SettingsStore
  constructor(settingsStore: SettingsStore) {
    makeAutoObservable(this);
    this.settingsStore = settingsStore;
    this.wordsService = getWordsService()
  }

  async getStats() {
    try {
      const res = await this.wordsService.getStats(this.settingsStore.getLang())
      if (res.ok) {
        this.stats = res.value
      }
    } catch (error) {
      console.error('Failed to load user data', error);
    }
  }

  async setAnswer(id: number) {
    return new Promise(async (resolve) => {
      const now = new Date().getTime()
      const last = this.currentIndex === this.answers.length - 1

      this.lastAnswers.push(id)
      this.answers[this.currentIndex].success = id === this.train[this.currentIndex].word.id
      this.answers[this.currentIndex].time = Math.trunc((now - this.time))

      if (last) {
        await this.wordsService.setTrainResult(this.settingsStore.getLang(), this.answers)
      }

      if (this.answers[this.currentIndex].success) {
        this.currentIndex++
        this.time = now
        resolve(last)
      } else {
        const id = setTimeout(() => {
          clearTimeout(id)
          this.currentIndex++
          this.time = new Date().getTime()
          resolve(last)
        }, 2000)
      }
    })
  }

  getLastAnswerId(): number {
    return this.lastAnswers[this.lastAnswers.length - 1]
  }

  getTrainItem(): TrainItem|null {
    return this.train[this.currentIndex] ? this.train[this.currentIndex] : null
  }

  getCurrentAnswer(): Answer|null {
    return this.answers[this.currentIndex] ? this.answers[this.currentIndex] : null
  }

  async getTrain() {
    try {
      this.answers = []
      this.train = []
      this.lastAnswers = []
      this.currentIndex = 0
      const res = await this.wordsService.getTrain(this.settingsStore.getLang())
      if (res.ok) {
        this.train = res.value
        this.answers = res.value.map(it => ({
          id: it.word.id,
          success: false,
          time: 0
        }))
        this.time = new Date().getTime()
      }
    } catch (error) {
      console.error('Failed to load user data', error);
    }
  }
}
export default WordsStore;