import { makeAutoObservable } from 'mobx';
import { getGameService } from '../sevices/singletones';
import { SuccessTasks, Task } from '../sevices/types';
import { IGameService } from '../sevices/gameService';
import userStore from '../stores/user';


export class GameStore {
  loadedTasks: boolean = false
  tasks: Task[] = [];
  successTasks: SuccessTasks|null = null
  private gameService: IGameService
  private checkerId: NodeJS.Timer | undefined

  constructor() {
    makeAutoObservable(this);
    this.gameService = getGameService()
  }

  private constructTask(tasks: Task[], successTasks: SuccessTasks) {
    const result: { confirmed: Task[], available: Task[] } = { confirmed: [], available: [] }
    const data = tasks.map(task => {
      const success = successTasks.tasks.find(it => it.id === task.id)
      const confirmed = success ? success.confirmed : 0
      let isChecking = false
      if (success && confirmed === 0) {
        isChecking = true
      }
      
      return {
        ...task,
        claimed: success ? 1 : 0,
        isChecking: isChecking,// && confirmed == 0 ? false : true,
        confirmed: confirmed
      }
    }).reduce((result, item) => {
      if (item.confirmed) {
        result.confirmed.push(item)
      } else {
        result.available.push(item)
      }
      return result
    }, result)
    return data.available.concat(data.confirmed)
  }

  private async checkTasks(ids: number[]) {
    try {
      const res = await this.gameService.checkTasks(ids)
      if (res.ok) {
        const confirmed = res.value.success.tasks.filter(it => ids.includes(it.id) && it.confirmed === 1)
        if (confirmed.length) {
          await userStore.loadUserData()
        }
        this.tasks = this.constructTask(res.value.tasks, res.value.success)
        this.successTasks = res.value.success
      }
    } catch (error) {
      console.error('Failed to load user data', error);
    }
  }

  private taskChecker() {
    if (this.checkerId) {
      clearInterval(this.checkerId)
    }

    this.checkerId = setInterval(() => {
      const unconfirmed = this.successTasks?.tasks.filter(it => {
        const task = this.tasks.find(task => task.id === it.id)
        return task?.isChecking
      }).map(it => it.id) || []
      console.log('unconfirmed', unconfirmed)
      if (unconfirmed.length) {
        this.checkTasks(unconfirmed)  
      } else {
        this.checkerId && clearInterval(this.checkerId)
      }
    }, 5000)
  }

  async confirmTask(id: number) {
    try {
      const res = await this.gameService.confirmTask(id)
      if (res.ok) {
        this.tasks = this.constructTask(res.value.tasks, res.value.success)
        this.successTasks = res.value.success
        this.taskChecker()
      }
    } catch (error) {
      console.error('Failed to load user data', error);
    }
  }

  async loadTasks() {
    this.loadedTasks = true
    try {
      const res = await this.gameService.getTasks()
      if (res.ok) {
        this.tasks = this.constructTask(res.value.tasks, res.value.success)
        this.successTasks = res.value.success
        this.loadedTasks = true
        this.taskChecker()
      }
            
    } catch (error) {
      console.error('Failed to load user data', error);
    }
  }
}

const gameStore = new GameStore();
export default gameStore;