import { Answer, ITransport, Result, Stats, TrainItem } from "./types";

export interface IWordsService {
  getStats(language: string): Promise<Result<Stats|null>>
  getTrain(language: string): Promise<Result<TrainItem[]>>
  setTrainResult(language: string, answers: Answer[]): Promise<Result<undefined>>
}

export class WordService implements IWordsService {
  private transport: ITransport;
  
  constructor(transport: ITransport) {
    this.transport = transport;
  }

  async getStats(language: string): Promise<Result<Stats|null>> {
    try {
      const response = await this.transport.request('getStats', { language })
      if (!response.ok) {
        return response
      }

      if (!response.value) {
        return { ok: true, value: null }
      }

      return { ok: true, value: response.value.stats }
    } catch (error) {
      return { ok: false, error: { code: 500, message: error } }
    }
  }

  async getTrain(language: string): Promise<Result<TrainItem[]>> {
    try {
      const response = await this.transport.request('getTrain', { language })
      if (!response.ok) {
        return response
      }
      return { ok: true, value: response.value.train }
    } catch (error) {
      return { ok: false, error: { code: 500, message: error } }
    }
  }
  
  async setTrainResult(language: string, answers: Answer[]): Promise<Result<undefined>> {
    try {
      const response = await this.transport.request('setTrainResult', { language, answers })
      if (!response.ok) {
        return response
      }
      return { ok: true, value: undefined }
    } catch (error) {
      return { ok: false, error: { code: 500, message: error } }
    }
  }
}