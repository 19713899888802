import { ITransport, Result, TaskAggregate } from './types';

export interface IGameService {
  getTasks(): Promise<Result<TaskAggregate>>
  confirmTask(id: number): Promise<Result<TaskAggregate>>
  checkTasks(ids: number[]): Promise<Result<TaskAggregate>>
}

export class GameService implements IGameService {
  private transport: ITransport;
  
  constructor(transport: ITransport) {
    this.transport = transport;
  }

  async getTasks(): Promise<Result<TaskAggregate>> {
    try {
      const response = await this.transport.request('getTasks', {})
      if (!response.ok) {
        return response
      }
      return { 
        ok: true, 
        value: { 
          tasks: response.value.tasks, 
          success: response.value.success 
        }}
    } catch (error) {
      return { ok: false, error: { code: 500, message: error } }
    }
  }

  async checkTasks(ids: number[]): Promise<Result<TaskAggregate>> {
    try {
      const response = await this.transport.request('checkTasks', { ids: ids })
      if (!response.ok) {
        return response
      }
      return { 
        ok: true, 
        value: { 
          tasks: response.value.tasks, 
          success: response.value.success 
        }}
    } catch (error) {
      return { ok: false, error: { code: 500, message: error } }
    }
  }

  async confirmTask(id: number): Promise<Result<TaskAggregate>> {
    try {
      const response = await this.transport.request('confirmTask', { taskId: id })
      if (!response.ok) {
        return response
      }
      return { 
        ok: true, 
        value: { 
          tasks: response.value.tasks, 
          success: response.value.success 
        }}
    } catch (error) {
      return { ok: false, error: { code: 500, message: error } }
    }
  }
}