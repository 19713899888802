import { makeAutoObservable } from 'mobx';
import { getUserService } from '../sevices/singletones';
import { ReferralStats, User } from '../sevices/types';
import { WebAppUser } from '@twa-dev/types';
import ReactGA from 'react-ga4'

export class UserStore {
  user: User = null;
  tgUser: WebAppUser | null = null;
  refLink: string = ''
  loadedStats: boolean = false 
  stats: ReferralStats = { 
    earnedCountAtLevel1: 0,
    earnedCountAtLevel2: 0,
    earnedCountAtLevel3: 0,
    referralsCountAtLevel1: 0,
    referralsCountAtLevel2: 0,
    referralsCountAtLevel3: 0
  }

  constructor() {
    makeAutoObservable(this);
  }

  setTGData(tgUser: WebAppUser) {
    this.tgUser = tgUser
  }

  setRefLink(link: string) {
    this.refLink = link
  }

  setToken(token: string) {
    const userService = getUserService()
    userService.setToken(token)
  }

  async getReferralStats() {
    this.loadedStats = true
    const userService = getUserService()
    const res = await userService.getReferralStats()
    if (res.ok) {
      this.stats = res.value
    }
  }

  async loadUserData() {
    try {
      const userService = getUserService()
      const res = await userService.resolveUser(this.refLink)
      if (res.ok) {
        this.user = res.value
        //ReactGA.set({userId: this.user?.id })
      }
            
    } catch (error) {
      console.error('Failed to load user data', error);
    }
  }
}

const userStore = new UserStore();
export default userStore;