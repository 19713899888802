import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { UserStore } from '../../stores/user';
import "./styles.css"
import { NavLink } from 'react-router-dom';
import { WordsStore } from '../../stores/words';
import { SettingsStore } from '../../stores/settings';

interface HomeProps {
  userStore?: UserStore;
  wordsStore?: WordsStore;
  settingsStore?: SettingsStore;
}

const Home: React.FC<HomeProps> =  inject('userStore', 'settingsStore', 'wordsStore')(observer((props) => {
  const { userStore, wordsStore, settingsStore } = props
  const [pressed, incPressed] = useState<number>(0)
  useEffect(() => {
    wordsStore?.getStats()
  }, [])

  const changeLang = () => {
    if (pressed < 5) {
      incPressed(pressed+1)
      return
    } 
    const lang = settingsStore?.getLang()
    const newLang = lang === 'de' ? 'en' : 'de'
    settingsStore?.setLang(newLang)
    incPressed(0)
  }

  return (
    <div className='absolute overflow-hidden w-full h-full'>
      <div className="flex flex-1 flex-col relative h-full overflow-y-auto">
        <div className="flex my-4 px-4 bg-black">
          <div className="h-14 w-14 overflow-hidden rounded-md mr-2">
            <div className='w-full h-full flex justify-center items-center bg-blue-600' onClick={changeLang}>
              <h1 className='text-2xl'>{userStore?.tgUser?.first_name.substring(0,1)}</h1>
            </div>
          </div>
          <div className="flex flex-1 flex-row h-14 align-middle items-center bg-neutral-900 rounded-md">
            <div className="text-md font-normal leading-5 p-3 flex flex-1 flex-row justify-between">
              <div>Всего: <b>{wordsStore?.stats?.total || '~'}</b></div>
              <div>Повтор: <b>{wordsStore?.stats?.toLearn || '~'}</b></div>
              <div>Изучено: <b>{wordsStore?.stats?.learned}</b></div>
            </div>
          </div>
          {/* <div className='flex items-center ml-2'>
          <button className="h-14 pl-4 pr-5 flex flex-col items-center justify-center bg-neutral-900 rounded-md" onClick={showBoostMenu}>
            <img className='w-5 h-5' src='./r.png'/>
            <div className='font-light text-xs'>Boost</div>
          </button>
          </div> */}
        </div>
        <main className="flex flex-1 flex-col pt-6 pb-[5rem] bg-neutral-900">
          <div className='px-4 pb-4'>
            <h1 className='text-2xl'>Тренировки</h1>
          </div>
          <div className='px-4 pb-8 text-lg'>
            <div className='text-xl'>Слово – Перевод</div>
            <div className='pb-4'>Эта тренировка подойдет для новичков, а также если вы готовитесь, например, к экзамену по чтению.</div>
            <NavLink to="/training?direct=1"
              className="rounded-md bg-blue-600 px-3.5 py-2.5 text-md font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              Начать тренировку
            </NavLink>
          </div>
          <div className='px-4 pb-6 text-lg'>
            <div className='text-xl'>Перевод - Слово</div>            
            <div className='pb-4'>Этот тренировка подходит для тех, кто продолжает учить слова, она позволяет практиковать активный словарный запас.</div>
            <NavLink to="/training?direct=0"
              className="rounded-md bg-blue-600 px-3.5 py-2.5 text-md font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              Начать тренировку
            </NavLink>
          </div>
        </main>
      </div>
    </div>
  );
}));



export default Home;
