import userStore from "./user";
import gameStore from "./game";
import WordsStore from "./words";
import settingsStore from "./settings";

const wordsStore = new WordsStore(settingsStore);
const stores = {
    userStore,
    gameStore,
    wordsStore,
    settingsStore,
};

export default stores;