import { Transport } from "./transport";
import { IUserService, UserService } from "./userService";
import { apiHost } from '../settings'
import { GameService, IGameService } from "./gameService";
import { ITransport } from "./types";
import { IWordsService, WordService } from "./wordsService";
import { ISettingsService, SettingsService } from "./settingsService";

let transportService: ITransport
let userServiceInstance: IUserService;
let gameServiceInstance: IGameService;
let wordsServiceInstance: IWordsService;
let settingsServiceInstance: ISettingsService;

export const getTransport = (): ITransport => {
  if (!transportService) {
    transportService = new Transport(apiHost)
  }
  return transportService
}

export const getUserService = (): IUserService => {
  if (!userServiceInstance) {
    const transport = getTransport()
    userServiceInstance = new UserService(transport)
  }
  return userServiceInstance
}

export const getSettingsService = (): ISettingsService => {
  if (!settingsServiceInstance) {
    const transport = getTransport()
    settingsServiceInstance = new SettingsService()
  }
  return settingsServiceInstance
}

export const getGameService = (): IGameService => {
  if (!gameServiceInstance) {
    const transport = getTransport()
    gameServiceInstance = new GameService(transport)
  }
  return gameServiceInstance
}

export const getWordsService = (): IWordsService => {
  if (!wordsServiceInstance) {
    const transport = getTransport()
    wordsServiceInstance = new WordService(transport)
  }
  return wordsServiceInstance
}