import { makeAutoObservable } from 'mobx';
import { getSettingsService, getUserService } from '../sevices/singletones';

export class SettingsStore {
  private lang: string = 'de';
 
  constructor() {
    makeAutoObservable(this);
  }

  getLang(): string {
    const service = getSettingsService()
    return service.getLang()
  }

  setLang(lang: string): void {
    const service = getSettingsService()
    return service.setLang(lang)
  }
}

const settingsStore = new SettingsStore();
export default settingsStore;